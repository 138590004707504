import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";  // Import Firebase Storage

// Firebase configuration (Replace with your own config from Firebase Console)
const firebaseConfig = {
    apiKey: "AIzaSyDjDAPtY8NAN93lOPPihyhneplvzUu9PbE",
    authDomain: "rsdigital-33a8f.firebaseapp.com",
    projectId: "rsdigital-33a8f",
    storageBucket: "rsdigital-33a8f.appspot.com",
    messagingSenderId: "634752680893",
    appId: "1:634752680893:web:64ab2b472c8d0b5b5dc8d4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// Initialize Firebase Storage
export const storage = getStorage(app);

export { auth, db };
import React, { useState } from 'react';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubscribe = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Sticky Footer */}
      <footer className="bg-[#111827] pt-10 relative text-white">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Left Section: Logo and Text */}
          <div className="col-span-1">
            <img src="/Images/logo-white.webp?v=1" alt="RedSprout Digital Logo" className="h-16 mb-4" />
            <p className="text-white text-justify">
              At RedSprout Digital, we combine creativity with growth. Our passionate team helps build your online presence and supports your business success in the fast-paced digital world, ensuring lasting impact and steady growth through tailored digital strategies.
            </p>
          </div>

          {/* Industries We Serve */}
          <div className="col-span-1">
            <h3 className="font-semibold text-white mb-4">Industries We Serve</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-white hover:text-red-500">E-commerce</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Healthcare</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Finance</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Real Estate</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Education</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Technology</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Tour and Travel</a></li>
            </ul>
          </div>

          {/* Services */}
          <div className="col-span-1">
            <h3 className="font-semibold text-white mb-4">Services</h3>
            <ul className="space-y-2">
              <li><a href="/website-development" className="text-white hover:text-red-500">Web Development</a></li>
              <li><a href="/seo" className="text-white hover:text-red-500">Search Engine Optimization</a></li>
              <li><a href="/google-ads" className="text-white hover:text-red-500">Google Ads</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Social Media Marketing</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Content Marketing</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Graphic Design</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Social Media Marketing Tool</a></li>
            </ul>
          </div>

          {/* Resources */}
          <div className="col-span-1">
            <h3 className="font-semibold text-white mb-4">Resources</h3>
            <ul className="space-y-2">
              <li><a href="/blog" className="text-white hover:text-red-500">Blog</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Sign Up</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Log In</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Help Center</a></li>
              <li><a href="#" className="text-white hover:text-red-500">Refund Policy</a></li>
              <li><a href="/privacy-policy" className="text-white hover:text-red-500">Privacy Policy</a></li>
              <li><a href="/terms-and-conditions" className="text-white hover:text-red-500">Terms & Conditions</a></li>
            </ul>
          </div>
        </div>

        {/* Newsletter Subscription */}
        <div className="container mx-auto px-4 mt-16">
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div>
              <h3 className="font-semibold text-black mb-3">Subscribe to our newsletter</h3>
              <p className="text-gray-700">The latest news, articles, and resources, sent to your inbox weekly.</p>
            </div>
            <div className="flex space-x-2 w-full md:w-auto">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 w-full rounded-md border border-gray-300 text-black"
              />
              <button onClick={handleSubscribe} className="px-4 py-2 bg-[#e02b20] text-white rounded-md hover:bg-red-600">
                Subscribe
              </button>
            </div>
          </div>
        </div>

       {/* Footer Bar */}
<div className="container mx-auto px-4 mt-8 py-4 border-t border-gray-600 flex justify-between items-center">
  <p className="text-gray-300">
    © 2024 Made With 
    <span
      style={{
        display: 'inline-block',
        animation: 'heartBeat 1s infinite'
      }}
    >
      ❤️
    </span> 
    By RedSprout Digital.
  </p>
  <div className="flex space-x-2">
    <a href="https://www.facebook.com/redsproutdigital7" className="text-gray-300 hover:text-red-500"><img src="Images/facebook-icon.webp" alt="Facebook" className="h-5 w-5" /></a>
    <a href="https://www.instagram.com/digitalredsprout/" className="text-gray-300 hover:text-red-500"><img src="Images/instagram-icon.webp" alt="Instagram" className="h-5 w-5" /></a>
    <a href="https://www.linkedin.com/company/redsprout-digital" className="text-gray-300 hover:text-red-500"><img src="Images/linkedin-icon.webp" alt="LinkedIn" className="h-5 w-5" /></a>
  </div>
</div>

{/* Inline CSS for Animation */}
<style>
{`
  @keyframes heartBeat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
`}
</style>
      </footer>

      {/* Modal for Newsletter Confirmation */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-semibold mb-4">Thank you for subscribing!</h2>
            <p className="text-gray-600 mb-6">You have successfully subscribed to our newsletter.</p>
            <button onClick={closeModal} className="px-4 py-2 bg-[#e02b20] text-white rounded-md hover:bg-red-600">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;

import React, { useState } from 'react';
import axios from 'axios';  // Import axios to make the API request

const services = [
  {
    title: 'Study Abroad Edu',
    subtitle: 'Digital Marketing Services for Overseas Education',
    description: 'At RedSprout Digital, we specialize in delivering tailored digital marketing services for the overseas education industry, enhancing student engagement and boosting enrollment through expert SEO and targeted campaigns.',
    imageUrl: '/Images/1.webp',
   
  },
  {
    title: 'IT/Software',
    subtitle: 'Digital Marketing Services for IT/Software Industry',
    description: 'RedSprout Digital provides specialized digital marketing services for the IT and software industry, focusing on driving lead generation, increasing visibility, and boosting engagement through targeted SEO, PPC, and content strategies.',
    imageUrl: '/Images/3.webp',
  },
  {
    title: 'Ecommerce',
    subtitle: 'Digital Marketing Services for Ecommerce Company',
    description: 'RedSprout Digital offers comprehensive digital marketing services for eCommerce companies, enhancing brand visibility, driving online sales, and maximizing ROI through targeted SEO, PPC, and content marketing strategies.',
    imageUrl: '/Images/4.webp',
  },
  {
    title: 'Healthcare & Doctors',
    subtitle: 'Digital Marketing Services for Healthcare & Doctors',
    description: 'RedSprout Digital delivers tailored digital marketing services for healthcare and doctors, focusing on patient engagement, improving online visibility, and driving growth through SEO, PPC, and targeted content strategies.',
    imageUrl: '/Images/7.webp',
  },
  {
    title: 'Interior Designer',
    subtitle: 'Digital Marketing Services for Interior Designer',
    description: 'RedSprout Digital provides specialized digital marketing services for interior design companies, boosting brand presence, attracting high-quality leads, and driving engagement through strategic SEO, PPC, and visually compelling content marketing.',
    imageUrl: '/Images/8.webp',
  },
  {
    title: 'Real Estate',
    subtitle: 'Digital Marketing Services for Real Estate Business',
    description: 'RedSprout Digital offers expert digital marketing services for the real estate sector, driving property inquiries, enhancing brand visibility, and increasing sales through targeted SEO, PPC, and innovative content strategies.',
    imageUrl: '/Images/9.webp',
  },
  {
    title: 'Legal and Laws',
    subtitle: 'Digital Marketing Services for Legal Services',
    description: 'RedSprout Digital specializes in digital marketing services for legal and law firms, enhancing online visibility, attracting qualified leads, and driving client growth through targeted SEO, PPC, and content marketing strategies.',
    imageUrl: '/Images/2.webp',
  },
  {
    title: 'Tour and Travels',
    subtitle: 'Digital Marketing Services for Tour & Travel Companies',
    description: 'RedSprout Digital provides tailored digital marketing services for tour and travel companies, boosting online visibility, driving bookings, and enhancing customer engagement through strategic SEO, PPC, and content marketing.',
    imageUrl: '/Images/6.webp',
  },
  {
    title: 'Logistics & Transport',
    subtitle: 'Digital Marketing Services for Logistics & Transport',
    description: 'RedSprout Digital offers specialized digital marketing services for the logistics and transport industry, driving business growth, improving visibility, and generating leads through targeted SEO, PPC, and strategic content marketing.',
    imageUrl: '/Images/5.webp',
  },
];
const ServicesSection = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    business: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleButtonClick = () => {
    setShowPopup(true);
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(false);  // Reset loading state when the popup opens
  };

  const closePopup = () => {
    setShowPopup(false);
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(false);  // Reset loading state when popup closes
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone || !formData.business) {
      setErrorMessage('All fields are required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true); // Start spinner when the form is submitted

    try {
      // Sending the form data to the backend with the subject
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
        ...formData,
        subject: 'New Inquiry from RedSprout Digital | Industries We Serve PopUp', // Add subject here
      });
      if (response.status === 200) {
        setSuccessMessage('Your quote request has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', business: '' });  // Clear form fields
        setLoading(false);  // Stop spinner once the request is successful
        // Automatically close the popup after 3 seconds
        setTimeout(() => {
          closePopup();
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        setErrorMessage('Failed to submit the request. Please try again.');
        setLoading(false); // Stop spinner if error occurs
      }
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to submit the request. Please try again.');
      setLoading(false); // Stop spinner if error occurs
    }
  };
  return (
    <section className="bg-gradient-to-tr from-red-100 via-white to-red-100 py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          {services.map((service, index) => (
            <div
              key={index}
              className="w-full md:w-1/3 px-4 mb-8 transform transition-all duration-300 hover:scale-105"
            >
              <div className="bg-[#111827] rounded-lg shadow-lg overflow-hidden">
                <img
                  src={service.imageUrl}
                  alt={service.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6 text-center">
                  <h3 className="text-2xl font-bold mb-2 text-white">{service.title}</h3>
                  <h4 className="text-lg font-semibold mb-2 text-gray-200">{service.subtitle}</h4>
                  <p className="text-gray-400 mb-4 text-justify">{service.description}</p>
                  <button
                    className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                    onClick={handleButtonClick}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Request a Quote</h2>
            {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                Phone (with country code)
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="business">
                  Nature of Business
                </label>
                <input
                  type="text"
                  id="business"
                  name="business"
                  value={formData.business}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Nature of Business"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  disabled={loading}  // Disable the button when loading
                  >
                    {loading ? (
                      <svg className="animate-spin h-5 w-5 mr-3 inline-block text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                    ) : (
                      'Submit'
                    )}
                </button>
                <button
                  type="button"
                  className="text-gray-600 hover:text-gray-900 transition duration-300"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default ServicesSection;
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const TermsAndConditions = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Terms and Conditions | RedSprout Digital</title>
          <meta
            name="description"
            content="Read the terms and conditions for using RedSprout Digital's website and services. Learn about your rights, responsibilities, and our commitment to your privacy and security."
          />
          <meta
            name="keywords"
            content="terms and conditions"
          />
          <meta property="og:title" content="Terms and Conditions | RedSprout Digital" />
          <meta property="og:description" content="Read the terms and conditions for using RedSprout Digital's website and services. Learn about your rights, responsibilities, and our commitment to your privacy and security." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/terms-and-conditions/" />
          <link rel="canonical" href="https://redsproutdigital.com/terms-and-conditions/" />
        </Helmet>
        <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-extrabold mb-4 text-center">Terms and Conditions</h1>
            <h2 className="text-2xl font-bold mb-2">Terms and Conditions</h2>
            <p className="mb-4 text-gray-600 text-left">Last updated: December 07, 2023</p>

            <h2 className="text-2xl font-bold mb-2">OVERVIEW</h2>
            <p className="mb-4">
              Welcome to RedSprout Digital! These are our straightforward terms and conditions. By using our website, you agree to follow these rules. Please read them carefully.
            </p>

            <h2 className="text-2xl font-bold mb-2">USING OUR SERVICES</h2>
            <p className="mb-4">
              You should be of legal age in your area to use our services, or you need permission from a guardian if you’re a minor.
            </p>
            <p className="mb-4">
              Don’t use our services for anything illegal and be sure to follow the law.
            </p>
            <p className="mb-4">
              Please don’t send viruses or harmful code to our site.
            </p>
            <p className="mb-4">
              If you break any of these rules, we can stop providing our services to you.
            </p>

            <h2 className="text-2xl font-bold mb-2">HOW TO USE OUR WEBSITE</h2>
            <p className="mb-4">
              We have the right to refuse service to anyone, for any reason.
            </p>
            <p className="mb-4">
              Information you share with us may not always be encrypted, but credit card info is always safe.
            </p>
            <p className="mb-4">
              Don’t copy, sell, or misuse any part of our service without our permission.
            </p>

            <h2 className="text-2xl font-bold mb-2">INFORMATION ACCURACY</h2>
            <p className="mb-4">
              The information on our website may not always be perfect. Use it as a general guide, but consult better sources for important decisions.
            </p>
            <p className="mb-4">
              Some info might be old. We don’t have to update it, but you can always check back.
            </p>

            <h2 className="text-2xl font-bold mb-2">CHANGES TO PRICES AND SERVICES</h2>
            <p className="mb-4">
              Prices for our products may change without notice.
            </p>
            <p className="mb-4">
              We can modify or stop our services at any time without notice.
            </p>

            <h2 className="text-2xl font-bold mb-2">PRODUCTS AND SERVICES</h2>
            <p className="mb-4">
              Some products are only available online, and they might have limited quantities. Read our return policy for more details.
            </p>
            <p className="mb-4">
              We try to show accurate colors and images, but your screen might not show them exactly.
            </p>
            <p className="mb-4">
              We can choose not to sell our products or services to certain people or areas. We can change product descriptions and prices anytime.
            </p>
            <p className="mb-4">
              We don’t guarantee the quality of our products, and we’re not responsible for errors on our website.
            </p>

            <h2 className="text-2xl font-bold mb-2">BILLING AND ACCOUNT INFO</h2>
            <p className="mb-4">
              We can refuse your order for various reasons which will definitely be discussed with you.
            </p>
            <p className="mb-4">
              Make sure your account info is accurate and up-to-date.
            </p>

            <h2 className="text-2xl font-bold mb-2">OPTIONAL TOOLS</h2>
            <p className="mb-4">
              We offer some third-party tools, but they come with no guarantees. Use them at your own risk.
            </p>
            <p className="mb-4">
              We might add new services and features in the future, and they will also follow these terms.
            </p>

            <h2 className="text-2xl font-bold mb-2">LINKS TO OTHER WEBSITES</h2>
            <p className="mb-4">
              We can’t control other websites we link to. We’re not responsible for their content or any harm they cause. Be cautious and understand their terms before you use them.
            </p>

            <h2 className="text-2xl font-bold mb-2">COMMENTS AND FEEDBACK</h2>
            <p className="mb-4">
              If you send us comments, we can use them however we like without owing you anything.
            </p>
            <p className="mb-4">
              We may remove or edit comments we think are inappropriate.
            </p>

            <h2 className="text-2xl font-bold mb-2">PRIVACY</h2>
            <p className="mb-4">
              We handle your personal info as described in our Privacy Policy.
            </p>

            <h2 className="text-2xl font-bold mb-2">ERRORS AND OMISSIONS</h2>
            <p className="mb-4">
              We might have errors on our website, and we can change things without telling you in advance.
            </p>

            <h2 className="text-2xl font-bold mb-2">USE RESTRICTIONS</h2>
            <p className="mb-4">
              Please don’t use our website for anything illegal, harmful, or annoying. Don’t try to break our security measures.
            </p>

            <h2 className="text-2xl font-bold mb-2">WARRANTIES AND LIABILITY</h2>
            <p className="mb-4">
              We can’t guarantee our service will always work perfectly.
            </p>
            <p className="mb-4">
              If something goes wrong while using our service, we can sort the way out to come to a mutual decision.
            </p>

            <h2 className="text-2xl font-bold mb-2">INDEMNIFICATION</h2>
            <p className="mb-4">
              You have to cover our costs if you violate these terms or the law.
            </p>

            <h2 className="text-2xl font-bold mb-2">SEVERABILITY</h2>
            <p className="mb-4">
              If one part of these terms isn’t enforceable, the rest still apply.
            </p>

            <h2 className="text-2xl font-bold mb-2">TERMINATION</h2>
            <p className="mb-4">
              You or we can end these terms anytime. If you break the terms, we can stop providing our services without notice.
            </p>

            <h2 className="text-2xl font-bold mb-2">ENTIRE AGREEMENT</h2>
            <p className="mb-4">
              These terms are the whole deal between you and us. Any earlier agreements don’t matter.
            </p>

            <h2 className="text-2xl font-bold mb-2">GOVERNING LAW</h2>
            <p className="mb-4">
              These terms follow the laws of India and the jurisdiction of Delhi.
            </p>

            <h2 className="text-2xl font-bold mb-2">CHANGES TO TERMS</h2>
            <p className="mb-4">
              These terms might change, and it’s your job to keep up with them.
            </p>

            <h2 className="text-2xl font-bold mb-2">REFUNDS</h2>
            <p className="mb-4">
              For refunds and related questions, email us at <a href="mailto:contact@redsproutdigital.com" className="text-blue-600 hover:underline">contact@redsproutdigital.com</a>
            </p>

            <h2 className="text-2xl font-bold mb-2">CONTACT</h2>
            <p className="mb-4">
              If you have any questions about these terms, reach out to us at <a href="mailto:contact@redsproutdigital.com" className="text-blue-600 hover:underline">contact@redsproutdigital.com</a>
            </p>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default TermsAndConditions;

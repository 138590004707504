import React, { useState } from 'react';
import axios from 'axios';  // Import axios to make the API request

const ContentUSA = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Add loading state

  const handleButtonClick = () => {
    setShowPopup(true);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(false);  // Reset loading state when popup is closed

  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone || !formData.website) {
      setErrorMessage('All fields are required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
        setLoading(true);  // Set loading to true when form submission starts


    try {
      // Sending the form data to the backend with the subject
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
        ...formData,
        subject: 'New Inquiry from RedSprout Digital | DMA USA (Pop Up)', // Add subject here
      });
      if (response.status === 200) {
        setSuccessMessage('Your quote request has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', website: '' });
        setLoading(false);  // Stop spinner once the request is successful
        // Automatically close the popup after 3 seconds
        setTimeout(() => {
          closePopup();
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        setErrorMessage('Failed to submit the request. Please try again.');
        setLoading(false);  // Stop spinner if request fails

      }
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to submit the request. Please try again.');
      setLoading(false);  // Stop spinner if request fails

    }
  };


  return (
    <div>

    {/* Full-Width Paragraph Section */}
    <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
   <div className="container mx-auto px-4">
     <p className="text-gray-700 text-justify">
     Welcome to RedSprout Digital, a top-rated digital marketing agency in USA dedicated to providing innovative and results-driven digital solutions. Our goal is to help businesses thrive online by implementing effective digital marketing strategies that drive traffic, boost visibility, and increase conversions. Whether you’re seeking SEO services, paid ads management, or website development, we’ve got you covered.
     </p>
   </div>
 </div>
 {/* Google Ads Section */}
 <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
   <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
     <div className="relative">
       <img
         src="/Images/expert_website_and_ecommerce_development_in_usa.webp"
         alt="Expert Website and E-Commerce Development in USA"
         className="rounded-lg shadow-lg"
       />
     </div>
     <div className="md:pl-12">
       <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
       Expert Website and E-Commerce Development in USA  
       </h2>
       <p className="text-gray-700 mb-6">
       RedSprout Digital is the best website development company in the USA, creating professional and responsive websites tailored to your business needs. Whether you need a simple brochure site or complex e-commerce website development, we deliver exceptional user experiences.
       </p>
       <p className="text-gray-700 mb-6">
       Specializing in e-commerce website development, we enhance shopping experiences and drive sales. As a top web development company in the USA, we design user-friendly, secure, and conversion-optimized websites to help your business grow with a seamless online storefront.
       </p>
     
       <button
         className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
         onClick={handleButtonClick}
       >
         Get Quote
       </button>
     </div>
   </div>
 </div>

 {/* About Us Section with Text on Left and Image on Right */}
 <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
   <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
     <div className="md:pr-12">
       <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
       Targeted Paid Ads Management in the USA
       </h2>
       <p className="text-gray-700 mb-6">
       At RedSprout Digital, we craft targeted and effective paid advertising campaigns across platforms like Google, Facebook, and Instagram. Our digital marketing agency in the USA ensures your ads reach the right audience, driving high returns on investment (ROI).
       </p>
       <p className="text-gray-700 mb-6">
       From campaign setup to ongoing optimization, we maximize your ad spend to deliver the best possible results. Our focus is on ensuring every dollar you invest generates meaningful engagement and contributes to your business growth.
       </p>
       <button
         className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
         onClick={handleButtonClick}
       >
         Get Quote
       </button>
     </div>
     <div className="relative">
       <img
         src="/Images/targeted_paid_ads_management_in_the_usa.webp"
         alt="Targeted Paid Ads Management in the USA"
         className="rounded-lg shadow-lg"
       />
     </div>
   </div>
 </div>

 {/* Our Solutions Section */}
 <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
   <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
     <div className="relative">
       <img
         src="/Images/professional_expert_seo_services_in_the_usa.webp"
         alt="Professional Expert SEO Services in the USA"
         className="rounded-lg shadow-lg"
       />
     </div>
     <div className="md:pl-12">
       <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
       Professional Expert SEO Services in the USA
       </h2>
       <p className="text-gray-700 mb-6">
       Our SEO agency in the USA specializes in boosting search engine rankings and driving organic traffic to your site. We employ a comprehensive approach, focusing on both on-page and off-page SEO tactics to help your business stand out in competitive markets.
       </p>
       <p className="text-gray-700 mb-6 ">
       From keyword optimization and content creation to strategic backlinking, our SEO services are designed to elevate your rankings and attract more customers. With RedSprout Digital, your business will gain the visibility it needs to thrive online.
       </p>
       <button
         className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
         onClick={handleButtonClick}
       >
         Get Quote
       </button>
     </div>
   </div>
 </div>

 {/* About Us Section with Text on Left and Image on Right */}
 <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
   <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
     <div className="md:pr-12">
       <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
       Social Media Marketing for Businesses in the USA </h2>
       <p className="text-gray-700 mb-6">
       In the competitive digital landscape of the USA, social media marketing is key to connecting with your audience. At RedSprout Digital, we design and execute targeted social media campaigns that build brand awareness, drive engagement, and generate leads across platforms like Facebook, Instagram, LinkedIn, and Twitter.
       </p>
       <p className="text-gray-700 mb-6">
       Our strategies focus on creating compelling content and running targeted ads that resonate with your audience. Whether you're looking to grow your following, boost engagement, or drive traffic to your website, our social media marketing expertise ensures your business stands out and thrives in the US market.
       </p>
       <button
         className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
         onClick={handleButtonClick}
       >
         Get Quote
       </button>
     </div>
     <div className="relative">
       <img
         src="/Images/social_media_marketing_for_businesses_in_the_usa.webp"
         alt="Social Media Marketing for Businesses in the USA"
         className="rounded-lg shadow-lg"
       />
     </div>
   </div>
 </div>

 {/* Popup Form */}
 {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Request a Quote</h2>
            {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone Number (with country code)
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="+1 234 567 8901"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
                  Website
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="https://yourwebsite.com"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  disabled={loading}  // Disable the button when loading
                  >
                    {loading ? (
                      <svg className="animate-spin h-5 w-5 mr-3 inline-block text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                    ) : (
                      'Submit'
                    )}
                </button>
                <button
                  type="button"
                  className="text-gray-600 hover:text-gray-900 transition duration-300"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};


export default ContentUSA;

import React, { useState } from 'react';
import axios from 'axios';  // Import axios to make the API request

const ContentAustralia = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Add loading state

  const handleButtonClick = () => {
    setShowPopup(true);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(false);  // Reset loading state when popup is closed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone || !formData.website) {
      setErrorMessage('All fields are required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);  // Set loading to true when form submission starts

    try {
      // Sending the form data to the backend with the subject
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
        ...formData,
        subject: 'New Inquiry from RedSprout Digital | DMA Australia (Pop Up)', // Add subject here
      });
      if (response.status === 200) {
        setSuccessMessage('Your quote request has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', website: '' });
        setLoading(false);  // Stop spinner once the request is successful
        // Automatically close the popup after 3 seconds
        setTimeout(() => {
          closePopup();
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        setErrorMessage('Failed to submit the request. Please try again.');
        setLoading(false);  // Stop spinner if request fails
      }
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to submit the request. Please try again.');
      setLoading(false);  // Stop spinner if request fails
    }
  };



  return (
    <div>

      {/* Full-Width Paragraph Section */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4">
          <p className="text-gray-700 text-justify">
            Welcome to RedSprout Digital, the premier digital marketing agency in Australia. We specialize in helping Australian businesses grow their online presence through a wide range of services, including SEO, website development, and e-commerce web design. Whether you’re looking to improve your search engine rankings or build a high-performing website, our team has the expertise to deliver exceptional results.
          </p>
        </div>
      </div>
      {/* Google Ads Section */}
      <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative">
            <img
              src="/Images/website_and_eCommerce_development_in_australia.webp"
              alt="Website & E-Commerce Development in Australia"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:pl-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Website & E-Commerce Development in Australia
            </h2>
            <p className="text-gray-700 mb-6">
              At RedSprout Digital, we deliver top-tier website development and e-commerce web design in Australia. Our fast, responsive, and visually captivating websites are designed to convert visitors into loyal customers, whether for corporate sites or e-commerce platforms.
            </p>
            <p className="text-gray-700 mb-6">
              We specialize in secure, conversion-optimized e-commerce web design in Australia. With custom e-commerce solutions, including intuitive user experience and efficient payment gateway integration, we help your business thrive in the competitive online retail market.
            </p>

            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section with Text on Left and Image on Right */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:pr-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Top Premier Leading SEO Agency in Australia
            </h2>
            <p className="text-gray-700 mb-6">
              At RedSprout Digital, we are a top SEO agency in Australia, specializing in both on-page and off-page SEO to boost your website’s search engine rankings. Through advanced keyword research, content optimization, and strategic link-building, we ensure your website ranks higher and attracts qualified traffic.
            </p>
            <p className="text-gray-700 mb-6">
              Our SEO Melbourne services are tailored to help businesses in and around Melbourne achieve enhanced visibility and increased search engine traffic. With our expertise, your business will thrive in the competitive Australian market. Partner with RedSprout Digital to boost your online presence and grow in the Australian market.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
          <div className="relative">
            <img
              src="/Images/top_premier_leading_seo_agency_in_australia.webp"
              alt="Top Premier Leading SEO Agency in Australia"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      {/* Our Solutions Section */}
      <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative">
            <img
              src="/Images/paid_ads_management_in_australia.webp"
              alt="Expert Strategic Paid Ads Management in Australia"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:pl-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Expert Strategic Paid Ads Management in Australia
            </h2>
            <p className="text-gray-700 mb-6">
              Looking to boost your digital reach quickly? Our expert paid ads management services in Australia cover Google Ads, Meta Ads (Facebook and Instagram), and other major digital platforms. We craft targeted ads that directly engage your audience, ensuring a high return on investment (ROI).
            </p>
            <p className="text-gray-700 mb-6 ">
              With our skills in A/B testing, audience segmentation, and continuous campaign optimization, we maximize the efficiency of your advertising budget, helping your business achieve the best possible results.Partner with RedSprout Digital to boost your revenue.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section with Text on Left and Image on Right */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:pr-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Innovative Digital Marketing Strategies in Australia
            </h2>
            <p className="text-gray-700 mb-6">
              At RedSprout Digital, we specialize in delivering cutting-edge digital marketing strategies designed to meet the specific needs of Australian businesses. Our approach is tailored to ensure your brand stands out in competitive markets like Sydney, Melbourne, Brisbane, and beyond. We focus on enhancing your online visibility, helping your business connect with the right audience.
            </p>
            <p className="text-gray-700 mb-6">
              Our targeted digital marketing campaigns are crafted to drive meaningful conversions, turning potential leads into loyal customers. With our expertise, we ensure your digital presence is not only visible but also impactful, contributing to sustained business growth across Australia.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
          <div className="relative">
            <img
              src="/Images/digital_marketing_strategies_in_australia.webp"
              alt="Innovative Digital Marketing Strategies in Australia"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

       {/* Popup Form */}
       {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Request a Quote</h2>
            {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone Number (with country code)
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="+1 234 567 8901"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
                  Website
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="https://yourwebsite.com"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  disabled={loading}  // Disable the button when loading
                  >
                    {loading ? (
                      <svg className="animate-spin h-5 w-5 mr-3 inline-block text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                    ) : (
                      'Submit'
                    )}
                </button>
                <button
                  type="button"
                  className="text-gray-600 hover:text-gray-900 transition duration-300"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};



export default ContentAustralia;

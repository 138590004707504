import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const ContactSection = () => {
  const [animate, setAnimate] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+1',
    phone: '',
    website: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAnimate(true);
  }, []);

 // Function to handle input changes
 const handleChange = (e) => {
  const { name, value } = e.target;

  // If the input is the phone number, ensure only digits are allowed
  if (name === 'phone') {
    if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  } else {
    setFormData({
      ...formData,
      [name]: value,
    });
  }
};

  // Form validation logic
  const validate = () => {
    const validationErrors = {};
    if (!formData.name.trim()) validationErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!formData.phone.trim()) validationErrors.phone = 'Mobile number is required';
    if (!formData.website.trim()) validationErrors.website = 'Website is required';
    if (!formData.message.trim()) validationErrors.message = 'Message is required';

    return validationErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const firstErrorField = Object.keys(validationErrors)[0];
      document.getElementsByName(firstErrorField)[0].focus();
    } else {
      setErrors({});
      setLoading(true);

      try {
        const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
          ...formData,
          subject: 'New Inquiry from RedSprout Digital | Contact Us',
        });

        setLoading(false);
        setSuccess(true);
        setFormData({
          name: '',
          email: '',
          countryCode: '+1',
          phone: '',
          website: '',
          message: '',
        });

        // Redirect to thank-you page after a short delay
        setTimeout(() => {
          navigate('/thank-you');
        }, 1000); // Redirect after 1 second
      } catch (error) {
        setLoading(false);
        alert('Failed to submit form');
      }
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000); // Hide the success message after 5 seconds
      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [success]);

  return (
    <div className="min-h-screen flex items-center justify-center relative bg-gradient-to-tr from-red-100 via-white to-red-100">
      <div className="relative w-full max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 p-6">
        {/* Left Section: Contact Info */}
        <div className="md:col-span-2 flex flex-col justify-between p-8 rounded-lg shadow-lg" style={{ backgroundColor: '#111827', color: '#FFFFFF' }}>
          {/* Simple Header: Get in Touch with Us */}
          <div className="text-left mb-6">
            <h1 className="text-4xl font-extrabold text-white">Get in Touch with Us</h1>
          </div>

          <div>
            <h2 className="text-3xl font-bold mb-4">Discover how we can help boost your revenue!</h2>
            <p className="mb-6">
              Unlock new growth opportunities tailored to your business needs. Our team is ready to assist you in maximizing your revenue potential. Provide your details here, and we'll reach out to you within one business day.
            </p>
          </div>

          {/* Contact Details Section */}
          <div className="mt-8">
            <h3 className="text-2xl font-semibold mb-4">Contact Information</h3>
            <ul>
              <li className="mb-2">
                <strong>Email:</strong> contact@redsproutdigital.com
              </li>
              <li className="mb-2">
                <strong>Office Address:</strong> New Delhi, India
              </li>
              <li className="mb-2">
                <strong>We work globally, remotely!</strong>
              </li>
            </ul>

            {/* Social Media Links */}
            <div className="mt-6 flex space-x-4">
              <a href="https://www.facebook.com/redsproutdigital7" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-white transition">
                <FaFacebookF size={24} />
              </a>
              <a href="https://www.instagram.com/digitalredsprout/" target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-white transition">
                <FaInstagram size={24} />
              </a>
              <a href="https://www.linkedin.com/company/redsprout-digital" target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-white transition">
                <FaLinkedinIn size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Right Section: Form */}
        <div className={`flex flex-col justify-between p-8 rounded-lg shadow-lg transform transition duration-700 ease-in-out ${animate ? 'animate-fadeInLeft' : ''}`} style={{ backgroundColor: '#111827', color: '#FFFFFF' }} id="contact-form">
          <h3 className="text-xl font-bold mb-4">Contact Us</h3>

          

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-semibold mb-1">Name*</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg focus:outline-none"
                style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                required
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-1">Email*</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg focus:outline-none"
                style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                required
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-1">Mobile Number (with Country Code)*</label>
              <div className="flex space-x-2">
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none text-black"
                >
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+91">+91 (India)</option>
                  {/* Add more country codes as needed */}
                </select>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-2/3 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                  style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                  required
                />
              </div>
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-1">Website*</label>
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg focus:outline-none"
                style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                required
              />
              {errors.website && <p className="text-red-500 text-sm">{errors.website}</p>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-1">How can we help you?*</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg focus:outline-none"
                style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                rows="4"
                required
              ></textarea>
              {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
            </div>

            <button
              type="submit"
              className="w-full py-2 rounded-lg bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 transition text-white"
              disabled={loading}
            >
              {loading ? (
                <svg className="animate-spin h-5 w-5 mr-3 inline-block text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Yes, Want to Grow My Business'
              )}
            </button>
          </form>
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeInLeft {
          from {
            opacity: 0;
            transform: translateX(-20px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        .animate-fadeInLeft {
          animation: fadeInLeft 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default ContactSection;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import axios from 'axios';

const HeroSection = () => {
  // Form data state
  const [formData, setFormData] = useState({
    name: '',
    countryCode: '+1',
    phone: '',
    email: '',
    companyName: '',
    service: '',
  });

  const [errors, setErrors] = useState({}); // Error state for form validation
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const navigate = useNavigate(); // Hook for redirection

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Validation logic
  const validate = () => {
    let validationErrors = {};

    if (!formData.name.trim()) validationErrors.name = 'Name is required';
    if (!formData.phone.trim()) validationErrors.phone = 'Mobile number is required';
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email is invalid';
    }
    if (!formData.companyName.trim()) validationErrors.companyName = 'Company name is required';
    if (!formData.service.trim()) validationErrors.service = 'Service is required';

    return validationErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set validation errors if found
    } else {
      setLoading(true); // Set loading to true when submission starts
      try {
        // Sending the form data to the backend with the subject
        const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
          ...formData,
          subject: 'New Inquiry from RedSprout Digital | SEO', // Add subject here
        });

        setLoading(false); // Set loading to false when submission ends
        // Redirect to /thank-you after successful submission
        navigate('/thank-you');
      } catch (error) {
        setLoading(false); // Set loading to false if there is an error
        alert('Failed to send email');
        console.error('There was an error sending the email:', error);
      }
    }
  };

  return (
    <div className="relative bg-white py-16 overflow-hidden">
      {/* Background Design */}
      <div className="absolute inset-0 bg-gradient-to-br from-red-100 via-white to-red-100">
        <div className="absolute inset-0 bg-grid-pattern opacity-20 z-0"></div>
      </div>

      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center relative z-10">
        {/* Left Side: Text and Buttons */}
        <div className="md:pr-12 animate-fade-slide-in-left">
          <h1 className="text-4xl md:text-6xl font-extrabold text-gray-900 mb-4">
          Boost your revenue and grow your brand with our advanced SEO!
          </h1>
          <p className="text-gray-500 mb-6">
           With RedSprout Digital’s Dynamic SEO Strategies. Are you ready to grow your business?
          </p>
          <div className="flex space-x-4">
            <button className="px-6 py-3 bg-transparent border border-gray-300 text-gray-900 rounded-lg shadow-lg hover:bg-gray-100 transition transform hover:scale-105 duration-300">
            Start Social Media Managetement for $99/m
            </button>
          </div>
        </div>

        {/* Right Side: Form */}
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Grow Your Business</h2>
            <p className="text-gray-500 mb-6">by Partnering with RedSprout Digital</p>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

              {/* Country Code and Mobile Input */}
              <div className="flex space-x-2">
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  className="w-1/2 px-4 py-2 border border-gray-300 rounded-lg text-black"
                >
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+91">+91 (India)</option>
                  <option value="+61">+61 (Australia)</option>
                </select>
                <input
                  type="text"
                  name="phone"
                  placeholder="Mobile Number"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-2/3 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
                />
              </div>
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}

              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

              <input
                type="text"
                name="companyName"
                placeholder="Your Company Name"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
              />
              {errors.companyName && <p className="text-red-500 text-sm">{errors.companyName}</p>}

              <div className="relative">
                <label className="text-gray-700">Looking for *</label>
                <select
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
                >
                  <option value="">Select a Service</option>
                  <option value="Website Development">Website Development</option>
                  <option value="SEO">SEO</option>
                  <option value="Content Marketing">Content Marketing</option>
                  <option value="Social Media Management">Social Media Management</option>
                </select>
              </div>
              {errors.service && <p className="text-red-500 text-sm">{errors.service}</p>}

              {/* Submit Button with Spinner */}
              <button
                type="submit"
                className="w-full px-6 py-3 bg-gradient-to-r from-red-500 via-red-600 to-red-700 text-white rounded-lg shadow-lg hover:from-red-600 hover:to-red-800 transition transform hover:scale-105 duration-300"
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 inline-block text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  'Get Proposal'
                )}
              </button>
            </form>
          </div>
        </div>
    </div>
  );
};

export default HeroSection;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { db } from '../firebase/Firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import axios from 'axios'; // Add axios for form submission

const BlogPostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: 'Digital Marketing', // Default service
    message: '',
  });
  const [formStatus, setFormStatus] = useState({ success: false, error: null });
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state to track form submission

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        console.log(`Fetching post with permalink: ${id}`);

        const postsRef = collection(db, 'posts');
        const q = query(postsRef, where('permalink', '==', `/blog/${id}`));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const postData = docSnap.data();
          setPost(postData);

          await fetchRelatedPosts(postData.categories || [], postData.tags || []);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching the blog post:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRelatedPosts = async (categories, tags) => {
      try {
        console.log('Fetching related posts...');
        const postsRef = collection(db, 'posts');
        const q = query(
          postsRef,
          where('categories', 'array-contains-any', categories),
          where('tags', 'array-contains-any', tags)
        );
        const querySnapshot = await getDocs(q);
        const relatedPostsData = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(post => post.permalink !== `/blog/${id}`);

        console.log('Related posts:', relatedPostsData);
        setRelatedPosts(relatedPostsData);
      } catch (error) {
        console.error('Error fetching related posts:', error);
      }
    };

    fetchPost();
  }, [id]);

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true
    try {
      // Add subject to formData before sending the request
      const dataToSend = {
        ...formData,
        subject: `Enquiry from Blog: ${post.title}`, // Adding subject to the form data
      };
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', dataToSend);
      setFormStatus({ success: true, error: null });
      setFormData({ name: '', email: '', phone: '', service: 'Digital Marketing', message: '' });
      setLoading(false);  // Stop spinner once the request is successful

      alert('Inquiry submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormStatus({ success: false, error: 'Failed to submit inquiry' });
      alert('Failed to submit inquiry');
    } finally {
      setIsSubmitting(false); // Set submitting state to false after completion
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!post) {
    return <p>No blog post found.</p>;
  }

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200;
    const textLength = text.split(/\s+/).length;
    const readingTime = Math.ceil(textLength / wordsPerMinute);
    return readingTime;
  };

  const formatDate = (date) => {
    return format(new Date(date.seconds * 1000), 'MMMM d, yyyy');
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{post.metaTitle || post.title}</title>
        <meta name="description" content={post.metaDescription || post.subtitle} />
        <meta name="keywords" content={post.keywords ? post.keywords.join(', ') : ''} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={post.metaTitle || post.title} />
        <meta property="og:description" content={post.metaDescription || post.subtitle} />
        <meta property="og:image" content={post.featuredImage || '#'} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="container mx-auto p-4 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left Column: Blog Content */}
        <div className="lg:col-span-2">
          {/* Breadcrumb */}
          <div className="text-gray-500 mb-4">
            <Link to="/blog" className="hover:text-gray-900">Blogs</Link> &gt; <span className="text-black font-semibold">Blog Post</span>
          </div>

          {/* Featured Image */}
          {post.featuredImage && (
            <img
              src={post.featuredImage}
              alt={post.title}
              className="mb-4 rounded-lg max-h-64 object-cover w-full sm:max-h-100"
            />
          )}

          {/* Title */}
          <h1 className="text-4xl font-bold mb-4">{post.title}</h1>

          {/* Category */}
          <div className="mb-2">
            {post.categories && post.categories.map(category => (
              <span key={category} className="inline-block bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm mr-2">
                {category}
              </span>
            ))}
          </div>

          {/* Date and Reading Time */}
          <div className="text-gray-500 mb-8 flex items-center">
            <span>{formatDate(post.postDate)}</span>
            <span className="mx-2">•</span>
            <span>{calculateReadingTime(post.body)} min read</span>
          </div>

          {/* Content */}
          <div className="prose max-w-none mb-8 text-justify">{post.body && parse(post.body)}</div>

          {/* Related Articles */}
          {relatedPosts.length > 0 && (
            <div className="related-articles">
              <h2 className="text-2xl font-bold mb-4">Related Articles</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {relatedPosts.map(relatedPost => (
                  <div key={relatedPost.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                    {relatedPost.featuredImage && (
                      <img
                        src={relatedPost.featuredImage}
                        alt={relatedPost.title || 'No Title'}
                        className="w-full h-32 sm:h-48 object-cover"
                      />
                    )}
                    <div className="p-4">
                      <h3 className="text-xl font-bold mb-2">{relatedPost.title || 'Untitled Post'}</h3>
                      <p className="text-gray-700 mb-4">
                        {relatedPost.body ? parse(relatedPost.body.split(' ').slice(0, 15).join(' ') + '...') : 'No content available...'}
                      </p>
                      <a href={relatedPost.permalink} className="text-red-600 hover:underline mt-4 block">
                        Read more
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Right Column: Sticky Inquiry Form */}
        <div className="lg:col-span-1">
          <div className="sticky top-20">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <form className="bg-black text-white shadow-md rounded-lg p-4" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="sr-only" htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  aria-label="Name"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  aria-label="Email"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="phone">Mobile Number</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  aria-label="Mobile Number"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Mobile Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="service">Service Interested In</label>
                <select
                  name="service"
                  id="service"
                  aria-label="Service Interested In"
                  className="w-full px-3 py-2 border rounded text-black"
                  value={formData.service}
                  onChange={handleChange}
                  required
                >
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Paid Ads">Paid Ads</option>
                  <option value="Website Development">Website Development</option>
                  <option value="SEO">SEO</option>
                  <option value="Social Media Marketing">Social Media Marketing</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  aria-label="Message"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  required
                />
              </div>

              <button
                type="submit"
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 flex items-center justify-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="animate-spin h-5 w-5 mr-2 border-4 border-t-transparent border-white rounded-full"></div>
                ) : (
                  'Submit'
                )}
              </button>

              {formStatus.error && <p className="text-red-500 mt-4">{formStatus.error}</p>}
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        .animate-spin {
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </HelmetProvider>
  );
};

export default BlogPostPage;

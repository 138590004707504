import React, { useState } from 'react';
import axios from 'axios';  // Import axios to make the API request

const ContentSingapore = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Add loading state

  const handleButtonClick = () => {
    setShowPopup(true);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(false);  // Reset loading state when popup is closed

  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone || !formData.website) {
      setErrorMessage('All fields are required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);  // Set loading to true when form submission starts

    try {
      // Sending the form data to the backend with the subject
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
        ...formData,
        subject: 'New Inquiry from RedSprout Digital | DMA Singapore (Pop Up)', // Add subject here
      });
      if (response.status === 200) {
        setSuccessMessage('Your quote request has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', website: '' });
        setLoading(false);  // Stop spinner once the request is successful
        // Automatically close the popup after 3 seconds
        setTimeout(() => {
          closePopup();
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        setErrorMessage('Failed to submit the request. Please try again.');
        setLoading(false);  // Stop spinner if request fails
      }
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to submit the request. Please try again.');
      setLoading(false);  // Stop spinner if request fails
    }
  };


  return (
    <div>

      {/* Full-Width Paragraph Section */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4">
          <p className="text-gray-700 text-justify">
            In today’s highly competitive business landscape, having a robust online presence is crucial for success. This is where a Digital Marketing Agency in Singapore comes into play. By leveraging the expertise of digital marketing professionals, your business can thrive in the digital space, gaining visibility and attracting more customers. Whether you’re looking for SEO services, social media marketing, or comprehensive digital strategies, choosing the right agency is key.
            At our digital marketing agency in Singapore, we provide cutting-edge solutions to help businesses succeed online. From optimizing your website to rank higher on search engines to creating engaging social media campaigns, we are dedicated to driving results that matter.
          </p>
        </div>
      </div>
      {/* Google Ads Section */}
      <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative">
            <img
              src="/Images/seo_the_backbone_of_digital_marketing_in_singapore.webp"
              alt="SEO - The Backbone of Digital Marketing in Singapore"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:pl-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              SEO - The Backbone of Digital Marketing in Singapore
            </h2>
            <p className="text-gray-700 mb-6">
              Search Engine Optimization (SEO) is essential for boosting your online visibility, making it easier for potential customers to find your business through relevant keyword searches. By optimizing your website, you drive more organic traffic and connect with your target audience effectively.
            </p>
            <p className="text-gray-700 mb-6">
              As a leading SEO firm in Singapore, we enhance your site with both on-page and off-page strategies. We refine your website’s content and structure while building authoritative backlinks to improve search engine rankings. Our local SEO expertise ensures your business shines in geographically targeted searches, helping you attract customers within Singapore.
            </p>

            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section with Text on Left and Image on Right */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:pr-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Digital Advertising (Paid Ads) for Fast Results in Singapore
            </h2>
            <p className="text-gray-700 mb-6">
              While organic strategies like SEO take time, digital advertising offers immediate visibility and leads through Paid Ads. As a top digital marketing agency in Singapore, we create targeted ad campaigns for quick, impactful results.
            </p>
            <p className="text-gray-700 mb-6">
              Our services include Google Ads to drive targeted traffic using keywords like “digital marketing agency Singapore” and “Singapore SEO firm.” We also specialize in social media ads, reaching your audience with tailored campaigns, and remarketing to re-engage site visitors, boosting conversions and ROI.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
          <div className="relative">
            <img
              src="/Images/digital_advertising_for_fast_results_in_singapore.webp"
              alt="Digital Advertising (Paid Ads) for Fast Results in Singapore"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      {/* Our Solutions Section */}
      <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative">
            <img
              src="/Images/web_development_for_seamless_experience_in_singapore.webp"
              alt="Web Development for Seamless Experience in Singapore"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:pl-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Web Development for Seamless Experience in Singapore
            </h2>
            <p className="text-gray-700 mb-6">
              At RedSprout Digital, we specialize in creating custom websites that deliver seamless user experiences for businesses in Singapore. Our development approach focuses on building fast, responsive, and intuitive websites tailored to the specific needs of your local audience.
            </p>
            <p className="text-gray-700 mb-6 ">
              We ensure that every site we develop is optimized for both desktop and mobile users, providing a consistent and engaging experience across all devices. With our expertise in the Singapore market, your website will not only look great but also drive higher engagement and conversions.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section with Text on Left and Image on Right */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:pr-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Social Media Marketing for Businesses in Singapore </h2>
            <p className="text-gray-700 mb-6">
              In a digitally connected city like Singapore, social media marketing is essential for engaging your audience. Our Singapore-based agency crafts campaigns that resonate with your target market, driving meaningful engagement and brand growth.
            </p>
            <p className="text-gray-700 mb-6">
              We specialize in creating compelling content and strategic plans to grow your social media presence. Additionally, our paid social advertising on platforms like Facebook, Instagram, and LinkedIn boosts visibility and attracts more customers to your business.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
          <div className="relative">
            <img
              src="/Images/social_media_marketing_for_businesses_in_singapore.webp"
              alt="Social Media Marketing for Businesses in Singapore"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

     {/* Popup Form */}
     {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Request a Quote</h2>
            {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone Number (with country code)
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="+1 234 567 8901"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
                  Website
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="https://yourwebsite.com"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  disabled={loading}  // Disable the button when loading
                  >
                    {loading ? (
                      <svg className="animate-spin h-5 w-5 mr-3 inline-block text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                    ) : (
                      'Submit'
                    )}
                </button>
                <button
                  type="button"
                  className="text-gray-600 hover:text-gray-900 transition duration-300"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );   
};
export default ContentSingapore;
